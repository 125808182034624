<template>
    <header id="header-main" v-bind:class="{ 'page-home': $route.path == '/' }">
        <div class="container">
            <h1 class="logo"><router-link :to="'/'" exact>nickpish studios</router-link></h1>
            <nav id="nav-primary">
                <ul>
                    <li><router-link to="/" exact>Home</router-link></li>
                    <li><router-link to="/projects">Projects</router-link></li>
                    <li><router-link to="/skills">Skills</router-link></li>
                    <li><router-link to="/about">About</router-link></li>
                    <li><a href="mailto:hello@nickpish.com">Contact</a></li>
                    <!-- <li><router-link to="/contact">Contact</router-link></li> -->
                </ul>
            </nav>
            <burger />
        </div>
    </header>
</template>

<script>
import Burger from "@/components/menu/Burger.vue";
export default {
    name: "Navigation",
    data() {
        return {};
    },
    components: {
        Burger,
    },
};
</script>

<style scoped lang="scss">
@import "@/scss/_header_nav_footer.scss";
</style>