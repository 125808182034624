<template>
    <div class="sidebar">
        <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        <transition name="slide">
            <div v-if="isPanelOpen" class="sidebar-panel">
                <nav id="nav-sidebar">
                    <ul @click="closeSidebarPanel">
                        <li><router-link to="/" exact>Home</router-link></li>
                        <li><router-link to="/projects">Projects</router-link></li>
                        <li><router-link to="/skills">Skills</router-link></li>
                        <li><router-link to="/about">About</router-link></li>
                        <li><a href="mailto:hello@nickpish.com">Contact</a></li>
                    </ul>
                </nav>
            </div>
        </transition>
    </div>
</template>
<script>
import { store, mutations } from "@/store.js";

export default {
    methods: {
        closeSidebarPanel: mutations.toggleNav,
    },
    computed: {
        isPanelOpen() {
            return store.isNavOpen;
        },
    },
};
</script>

<style lang="scss">
@import "@/scss/_burger_sidebar.scss";
</style>
