<template>
    <div id="burger" :class="{ active: isBurgerActive }" @click.prevent="toggle">
        <slot>
            <button type="button" class="burger-button" title="Menu">
                <span class="hidden">Toggle menu</span>
                <span class="burger-bar burger-bar--1"></span>
                <span class="burger-bar burger-bar--2"></span>
                <span class="burger-bar burger-bar--3"></span>
            </button>
        </slot>
    </div>
</template>
<script>
import { store, mutations } from "@/store.js";

export default {
    // data: () => ({
    //     isBurgerActive: false
    // }),
    computed: {
        isBurgerActive() {
            return store.isNavOpen;
        },
    },
    methods: {
        toggle() {
            mutations.toggleNav();
        },
    },
};
</script>

<style lang="scss">
@import "@/scss/_burger_sidebar.scss";
</style>
