<template>
    <section class="home">
        <div class="container">
            <div class="intro">
                <h1>
                    Hi, I'm Nick
                    <span>(aka nickpish studios)</span>
                </h1>
                <h2>
                    web developer &amp; visual designer
                    <br />based in Washington, DC
                </h2>
                <router-link :to="'/projects'" class="btn trans" exact>View projects</router-link>
            </div>
            <div class="dc-animation">
                <svg
                    id="dc-map"
                    data-name="dc map"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="white"
                    stroke-width="2"
                    fill="transparent"
                    viewBox="0 0 531.41 608.91"
                >
                    <path
                        id="monument"
                        class="monument"
                        d="M230.46,266.8l-6-10h-.23l-5.95,10-3.05,77.39h18.25Z"
                        transform="translate(-0.01 -0.01)"
                    />
                    <path
                        class="cls-1"
                        d="M409.29,287.9c.22-.41-.44-.9,0-1.2,3.55-2.47,4.35-6.79,6.79-10,1.68-2.22,2.31-4.94,3.16-7.53a6.05,6.05,0,0,0,.25-1.94c-.09-3.26.82-4.23,4.14-4.9a22.53,22.53,0,0,1,6.81.07c6.87.74,11.62-2.45,15.34-7.81.65-.94,1.46-1.78,2.25-2.73L198.52.72C133.45,67.92,67.23,133.43.78,199.27c2.79,2,3.54,5.16,5.68,7.48.76.82,2.46,2,1.09,3.84-.37.48.49.77.68,1.2.29.62.81,1.13.76,1.93-.19,3.09,1.52,5.4,3.12,7.8,2.25,3.39,5.13,6.25,7.56,9.47.83,1.09,1.58,2.12,3.16,2.29.36,0,2.67,0,1.23,1.85-.51.64.29.77.5.94,2.94,2.32,5.2,5.36,8.22,7.61,1.49,1.12,3.32,1.76,4.07,4,.66,2,3.59,2.62,5.86,1.95.27,1.14.75,2.14.37,3.47a4.48,4.48,0,0,0,1,4,9.75,9.75,0,0,1,1.32,2.54,8.92,8.92,0,0,1,.1,7.28,2.4,2.4,0,0,0,.44,2.42,24.49,24.49,0,0,0,5.27,4.52,32,32,0,0,1,8.15,6.87c2.51,3,5.27,5.77,9.43,6.67,1.87.41,3.59,1.54,5.36,2.38.42.2.59.69,1.22.74,4.57.38,8.66,2.13,12.7,4.24,1.61.84,3.53,1.37,5.29,2.08,2.31.94,4.52-.64,6.85-.18a3.18,3.18,0,0,0,2.16-.33"
                        transform="translate(-0.01 -0.01)"
                    />
                    <path
                        class="cls-1"
                        d="M261.66,607.71c14.11-14.27,28.17-28.58,42.33-42.8q27.67-27.76,55.22-55.68,26.09-26.43,52.29-52.77,25.89-26.16,51.79-52.3c15.25-15.47,30.64-30.81,46-46.22q10.6-10.68,21.16-21.4a1.06,1.06,0,0,0,.36-1.34l-80.42-80.93c-2,3.21-4.9,5.15-7.3,7.61a9.49,9.49,0,0,1-7.3,3c-3.89,0-7.77.51-11.67.47a2.78,2.78,0,0,0-2.71,2.85,2.64,2.64,0,0,0,0,.28c.2,4-.27,7.83-2.87,11.22a38.3,38.3,0,0,0-6.34,12.1,101.66,101.66,0,0,1-5.58,13.64c1.13.57,1.68,1.89,3.14,2.23-1.07,1.78-2.58,1.43-3.9,1.37-1.82-.08-3.17.14-4,2.19-.63,1.62-2.53,3.1-1.78,4.85a16.49,16.49,0,0,1,1.46,8.56c-.12,1.11.53,2.3.47,3.64-.36,7.23-3,13.94-4.46,20.92A24.19,24.19,0,0,1,392,360.61c-2.34,2.6-4.82,5.09-7.34,7.49-3.47,3.31-5.62,7.35-7.72,11.5-1.49,2.94-2.62,6.2-5.85,7.75-2.36,1.13-4.85,2-7.28,2.94-8.13,3.19-16.35,6.14-23.6,11.19-5.42,3.77-11.09,7.17-15.85,11.87-3.3,3.25-7.6,3.38-11.86,2.2-3.13-.87-6.19-2-9.25-3.14-4.21-1.55-6.69-.62-8.75,3.42s-4.39,7.82-6,12c-2.94,7.45-8.39,12.3-15.15,16a23.36,23.36,0,0,0-10.42,11c-1.74,3.89-3.06,8-4.68,11.9-3,7.22-6.33,14.36-8.75,21.72A159.28,159.28,0,0,0,243.83,512a120.19,120.19,0,0,1-2.78,13.16c-.26.92-1.42,1.32-1.06,2.41,1.22,3.8-2.28,7-1.46,10.7a13.48,13.48,0,0,0-2.35,8c-.12,4.13-1.57,8.14-1.64,12.41-.12,7.62-1.94,15.18-.88,22.86.39,2.89-.9,5.14-3.11,7a1.48,1.48,0,0,0,.94,1.27c3.07,1.5,3.82,4.43,4.59,7.33.42,1.6.63,3.25,1,4.86.59,2.54,6.15,5.91,8.45,4.76,1.65-.82,2.94-.45,4.43-.15,1.77.37,3.56.55,5.34.82,2,.29,4.11-.25,5.85,1.14"
                        transform="translate(-0.01 -0.01)"
                    />
                    <path
                        class="cls-1"
                        d="M296.44,401.72c.5-.85,1-1.74,2.17-1a2,2,0,0,0,1.71.19,11.76,11.76,0,0,1,5.83.34c4.51,1.19,9,2.62,13.37,4.15.68.24,1.66.64,1.91.36,1.47-1.61,3.61-2.16,5.17-3.59a86.22,86.22,0,0,1,16.53-11.93c6.13-3.38,12.91-5,19.46-7.29,2.68-.93,5.84-1,7.31-4.13s3.72-5.77,4.8-9,4.23-5.15,4.19-8.74c3.13-3.74,3.54-8.67,5.56-12.9a5.89,5.89,0,0,0-.62-6.12c-1.83-2.84-3.36-6-1-9.43.74-1.11,1.57-2.14,2.39-3.19a14.4,14.4,0,0,0,3.06-9.93c-.34-4.39-.64-8.65.63-12.9.93-3.09,1.4-6.43,4.84-8.08a2.77,2.77,0,0,0,1.41-1.9c.06-2.74,2.09-4.44,3.29-6.53,2.33-4,6-5.08,10.4-3.44"
                        transform="translate(-0.01 -0.01)"
                    />
                    <path
                        class="cls-1"
                        d="M101.39,296.17c1.11-1.51,2,.22,2.91.34,5.1.64,10.31.23,15.32,1.42a74.34,74.34,0,0,1,12.23,3.71c3.06,1.3,6.05,3,9.43,3.51a21.07,21.07,0,0,1,5.12,1.69,97.42,97.42,0,0,1,11.42,6.28c0,1-.33,2.21,1,3a2.23,2.23,0,0,1,1,2.42,50.7,50.7,0,0,0-.38,7.79c-.16,4.68,1.61,8.89,4,12.91a23.2,23.2,0,0,0,6.21,6.32c-1,2.69-.73,4.15.89,6.06,4.1,4.81,8.64,9.2,13.16,13.6a136.86,136.86,0,0,0,12.89,11.41c.7.53,1.6,1.61,2.62.73s.73-2.11.24-3.11a9.16,9.16,0,0,1-1.16-3.45c-.13-2.84-1.86-4.07-4.2-4.86a8.4,8.4,0,0,1-3.87-2.45c-1.51-1.76-1.19-3.52.44-5.12,1.78-1.75,3.91-1.74,6.11-1.67,1.36,0,2.52-.17,1.68-1.94-.48-1-.15-1.47.74-2,3-1.67,5.67-1.19,8.27.64.08.94-.62,1.52-.89,2.18,2.35,1.57,2.48,1.53,3.27,4.26a1.66,1.66,0,0,0,1.76,1.39c4.25-.39,5.9,2.51,7.26,5.67.5,1.14,1,2.69-1.18,2.89a1.28,1.28,0,0,0-1,.75c-1.31,2.19-3.17,1.85-5.13,1.2-3.16-1-4.3-.42-4.6,2.68-.16,1.72-1,2.75-2.69,2.64-1.91-.13-2.87.67-3.23,2.53,1.25,1,2.44,2,3.72,2.85,9.72,6.79,16.63,16,22.06,26.31,6.38,12.12,10.84,25.13,16.4,37.64,1,2.17,1.14,4.82,3.36,6.34"
                        transform="translate(-0.01 -0.01)"
                    />
                    <path
                        class="cls-1"
                        d="M246.83,452.3c0-9.57.17-19.14-.47-28.7-.25-3.72.52-7.52-.53-11.17-2.18-7.51-3.61-15.28-8.66-21.71-2.28-2.9-5.25-5-7.65-7.7s-4.8-4.91-7.26-7.32c-1.25-1.22-1.52-2.91-2.13-4.41-.55-1.34.59-1.85,2.13-2,7.28-.63,10.62,5.19,15,9.12,2.08,1.85,3.76,4.21,5.93,6,3.69,3,5.66,6.95,7.27,11.23a53.35,53.35,0,0,0,4.2,9.35c1.32,2.15,1.09,5.22,1.13,7.92v10.46c.05,4.54.15,9.08.24,13.62,0,1.73.8,2.31,2.43,1.95,3.55-.8,5.11-2.8,5.11-6.57v-3.28c1.33.33,2.37-.26,3.43.82a4.89,4.89,0,0,0,6.92,0,4.82,4.82,0,0,0,1-1.47c1.63-4.06,4.25-7.52,6.2-11.38,1.81-3.59,3.23-7.44,6-10.48a23.46,23.46,0,0,1,8.28-6.07c.36-.15.48.32.73.48"
                        transform="translate(-0.01 -0.01)"
                    />
                    <path
                        class="cls-1"
                        d="M393.23,340.43c-.24-.16-.36-.43-.23-.47,2.34-.87,2-3.09,2.4-4.89.91-4.42,2.46-8.73,2.25-13.37-.06-1.41-.37-2.76-.56-4.13-.59-4.29,1.28-7.71,3.69-10.94a40.56,40.56,0,0,0,4.68-8c1.18-2.66,2-5.48,3.69-8.17-2.09-.52-3.32-.1-3.75,1.82a9,9,0,0,1-3.91,6.06,5.08,5.08,0,0,0-2.2,3.66,18.7,18.7,0,0,1-1.2,3.64,4.71,4.71,0,0,1-3.62,3c-1.84.36-4,3-3.86,4.83.51,4.79.27,9.67,1.66,14.35.71,2.4-.33,4.87.42,7.32a8.25,8.25,0,0,1,.55,4.11"
                        transform="translate(-0.01 -0.01)"
                    />
                    <path
                        class="cls-1"
                        d="M384.72,358.91c.26-.4-.33-.66,0-1.24,1.93-3.93,3.5-8.08,7.1-10.92"
                        transform="translate(-0.01 -0.01)"
                    />
                    <path
                        class="cls-1"
                        d="M394.21,339.94c-.78,2.48-2,4.82-2,7.54,0,1.26-.81,2.47-1.37,3.66-2.32,4.92-6.49,8.22-10.23,11.91"
                        transform="translate(-0.01 -0.01)"
                    />
                </svg>
            </div>
        </div>
        <!-- /.container -->
        <transition name="fadeIn">
            <div class="notify-bar">
                <!-- /. add v-if="notify" to use cookie -->
                <p>Welcome to my new website and thank you for visiting!</p>
            </div>
        </transition>
    </section>
</template>

<script>
// @ is an alias to /src
import Vivus from "vivus"; // import svg animation library
import VueCookies from "vue-cookies";
import HelloWorld from "@/components/HelloWorld.vue";

export default {
    name: "home",
    data() {
        return {
            notify: false,
        };
    },
    components: {
        // HelloWorld
    },
    mounted: function () {
        this.animateSvg();
        this.setCookie();
    },
    methods: {
        animateSvg: function () {
            this.el = document.getElementById("dc-map");
            new Vivus(this.el, {
                type: "delayed",
                duration: 250,
                animTimingFunction: Vivus.EASE,
            });
        },
        setCookie: function () {
            if (VueCookies.isKey("nps3-token")) {
                this.notify = false;
            } else {
                (this.notify = true), VueCookies.set("nps3-token", "default");
            }
            // console.log(this.notify)
        },
    },
    /*
    metaInfo: {
        title: "nickpish studios | washington, dc area-based web design & development",
        titleTemplate: null,
    },
    */
};
</script>

<style scoped lang="scss">
@import "@/scss/_home.scss";
</style>
