import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import VueCookies from "vue-cookies";
import axios from "axios";
import { VLazyImagePlugin } from "v-lazy-image";
import VueMeta from "vue-meta"
import VueGtag from "vue-gtag";

// import home initially, lazy-load the rest
import Home from "./views/Home.vue";

// import nprogress styles
import "../node_modules/nprogress/nprogress.css";

// import normalize
import "normalize.css";

// use axios globally
Vue.prototype.$http = axios;

// use vue-router plugin
Vue.use(VueRouter);

// use vue-cookies plugin
Vue.use(VueCookies);

// use v-lazy-image
Vue.use(VLazyImagePlugin);

// use vue-meta
Vue.use(VueMeta);

Vue.config.productionTip = false;

// define routes
const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            title: "home",
            transitionName: "fade"
        }
    },
    {
        path: "/projects",
        name: "projects",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "projects" */ "./views/Projects.vue"),
        meta: {
            title: "projects",
            transitionName: "slide"
        }
    },
    {
        path: "/skills",
        name: "skills",
        component: () =>
            import(/* webpackChunkName: "skills" */ "./views/Skills.vue"),
        meta: {
            title: "skills",
            transitionName: "slide"
        }
    },
    {
        path: "/about",
        name: "about",
        component: () =>
            import(/* webpackChunkName: "about" */ "./views/About.vue"),
        meta: {
            title: "about",
            transitionName: "slide"
        }
    },
    {
        path: "/contact",
        name: "contact",
        component: () =>
            import(/* webpackChunkName: "contact" */ "./views/Contact.vue"),
        meta: {
            title: "contact",
            transitionName: "slide"
        }
    },
    // not found / virtual 404 view
    {
        path: "*",
        name: "not-found",
        component: () =>
            import(
				/* webpackChunkName: "not-found" */ "./views/404NotFound.vue"
            ),
        meta: {
            title: "page not found",
            transitionName: "slide"
        }
    },
    // single project vue (dynamic)
    {
        path: "/projects/:project_name_slug",
        name: "single-project",
        component: () =>
            import(
				/* webpackChunkName: "single-project" */ "./views/SingleProject.vue"
            ),
        /*
        beforeEnter(to, from, next) {
            to.meta.title = to.params.project_name_slug;
            next();
        },
        */
        meta: {
            // title: (route) => route.params.project_name,
            title: "projects",
            transitionName: "slide"
        }
    }
];

// define router
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        let position = { x: 0, y: 0 };
        // Keep scroll position when using browser buttons
        if (savedPosition) {
            position = savedPosition;
        }
        // Workaround for transitions scrolling to the top of the page
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(position);
            }, 500);
        });
    }
});
export default router;

// show page titles
const DEFAULT_TITLE = "nickpish studios";
router.afterEach((to, from) => {
    // document.title = to.meta.title || DEFAULT_TITLE;
    // document.title = "nickpish studios - " + to.meta.title || DEFAULT_TITLE;
    document.title = to.meta.title + " | nickpish studios"
});

// enable nprogress via router
router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});

router.afterEach((to, from) => {
    NProgress.done();
});

// configure vue-gtag
Vue.use(VueGtag, {
    config: { id: "G-LH2KVM5HCT" }
}, router);

// instantiate vue
new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
