<template>
    <footer id="footer-main" v-if="$route.name !== 'home'">
        <div class="container">
            <div class="row">
                <div class="col-l nps-robot">
                    <img src="~@/assets/nps-robot-shadow-sm.png" alt="nps robot" />
                </div>
                <!-- /.col-l -->
                <div class="col-r">
                    <nav id="nav-footer">
                        <ul>
                            <li><router-link to="/" exact>Home</router-link></li>
                            <li><router-link to="/projects">Projects</router-link></li>
                            <li><router-link to="/skills">Skills</router-link></li>
                            <li><router-link to="/about">About</router-link></li>
                            <li><a href="mailto:hello@nickpish.com">Contact</a></li>
                        </ul>
                    </nav>
                    <div class="info">
                        <p>
                            <small>
                                &copy; 2023 nickpish studios
                                <br />All featured artwork/image copyrights belong to their respective owners.
                            </small>
                        </p>
                    </div>
                </div>
                <!-- /.col-r -->
            </div>
            <!--
            <p>
                <small>
                    &copy; 2021 nickpish studios
                    <br />All featured artwork/image copyrights belong to their respective owners.
                </small>
            </p>
            -->
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterMain",
    data() {
        return {};
    },
};
</script>

<style lang="scss">
@import "@/scss/_header_nav_footer.scss";
</style>
