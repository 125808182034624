<template>
    <div id="app">
        <!-- navigation START -->
        <navigation />
        <!-- navigation END -->

        <!-- view START -->
        <main v-bind:class="'page-' + $route.name">
            <transition-page>
                <router-view :key="$route.fullPath" />
            </transition-page>
        </main>
        <!-- view END -->

        <!-- footer START -->
        <footer-main />
        <!-- footer END -->

        <!-- sidebar START -->
        <Sidebar />
        <!-- sidebar END -->
    </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Sidebar from "@/components/menu/Sidebar";
import TransitionPage from "@/components/TransitionPage";
import FooterMain from "@/components/FooterMain";
export default {
    name: "App",
    data() {
        return {
            socialImg: "http://www.nickpishstudios.com" + require("./assets/nps-social-lg.png"),
        };
    },
    components: {
        Navigation,
        Sidebar,
        TransitionPage,
        FooterMain,
    },
    metaInfo() {
        return {
            // title: "nickpish studios",
            // titleTemplate: "%s | nickpish studios",
            meta: [
                {
                    vmid: "og:title",
                    property: "og:title",
                    content: "nickpish studios | " + this.$route.name,
                },
                {
                    property: "og:description",
                    content: "nickpish studios specializes in web development, UI/UX design and WordPress.",
                },
                {
                    vmid: "og:image",
                    property: "og:image",
                    content: this.socialImg,
                },
                {
                    vmid: "og:url",
                    property: "og:url",
                    content: "https://www.nickpishstudios.com" + this.$route.path,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    vmid: "twitter:title",
                    property: "twitter:title",
                    content: "nickpish studios | " + this.$route.name,
                },
                {
                    property: "twitter:description",
                    content: "nickpish studios specializes in web development, UI/UX design and WordPress.",
                },
                {
                    vmid: "twitter:image",
                    property: "twitter:image",
                    content: this.socialImg,
                },
                {
                    property: "twitter:card",
                    content: "summary_large_image",
                },
            ],
        };
    },
};
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,600i,700,700i');
// @import url("https://fonts.googleapis.com/css?family=Work+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap");
@import "@/scss/_variables.scss";
@import "@/scss/_global.scss";
@import "@/scss/_grid.scss";
@import "@/scss/fontisto/fontisto.scss";
</style>
